import {
  ApplicationMenu,
  ApplicationMenuItem,
} from '@energybox/react-ui-library/dist/components';
import {
  Sites as SitesIcon,
  Setup as SetupIcon,
  Overview as OverviewIcon,
  Notification as NotificationIcon,
  DynamicCalendar as DynamicCalendarIcon,
  Equipment as EquipmentIcon,
  ReportCentre as ReportCentreIcon,
} from '@energybox/react-ui-library/dist/icons';

import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, Route, withRouter } from 'react-router-dom';
import useGetUserPermission from '../hooks/useGetUserPermission';

import { toggleMobileMenu } from '../actions/app';
import { ApplicationState } from '../reducers';
import FeatureFlag from '../containers/auth/FeatureFlag';
import withAccessToken from '../containers/auth/withAccessToken';
import * as Routes from '../routes';
import {
  INSIGHT_PAGE_SUMMARY_TAB_WHITELIST,
  PERFORMANCE_PAGE_TAB_BLACKLIST,
} from '../utils/featureFlagSettings';

import styles from './Navigation.module.css';
import { determineShouldShowSwitchLink } from '@energybox/react-ui-library/dist/utils';
import { handlePlatformSwitch } from '../util';

interface Props extends RouteComponentProps {
  noNavigation?: boolean;
  routeToSavingsPage?: boolean; //CTRL-3718 - Delete after the POC for EB Platform Performance Review Dashboard
}

const menuItems = (routeToSavingsPage: boolean) => [
  // The ordering matters. Sites regex supersedes the other ones.
  {
    title: 'Today',
    icon: (
      <div className={styles.calendarIcon}>
        <DynamicCalendarIcon size={36} />
      </div>
    ),
    to: `${Routes.TODAY}`,
    activeOn: new RegExp(
      `^(${Routes.TODAY})|^(${Routes.HVAC_CONTROLS_OPERATIONS}?prev=today)`,
      'g'
    ),
  },
  {
    title: 'Insights',
    icon: <OverviewIcon size={30} />,
    to: `${Routes.SUMMARY}`,
    activeOn: new RegExp(`^(${Routes.DASHBOARD})|^(${Routes.SUMMARY})`, 'g'),
    featureFlag: {
      orgWhitelist: INSIGHT_PAGE_SUMMARY_TAB_WHITELIST,
    },
  },
  {
    title: 'Insights',
    icon: <OverviewIcon size={30} />,
    to:
      routeToSavingsPage === false
        ? `${Routes.DASHBOARD}`
        : `${Routes.SAVINGS}`,
    activeOn:
      routeToSavingsPage === false
        ? new RegExp(`^(${Routes.DASHBOARD})|^(${Routes.SUMMARY})`, 'g')
        : new RegExp(
            `^(${Routes.DASHBOARD})|^(${Routes.SUMMARY})|^(${Routes.SUSTAINABILITY})|^(${Routes.SAVINGS})|^(${Routes.RECOMMENDATIONS})`,
            'g'
          ),
    featureFlag: {
      orgBlacklist: INSIGHT_PAGE_SUMMARY_TAB_WHITELIST,
      applyBlacklistStrictly: true,
    },
  },
  {
    title: 'Notifications',
    icon: <NotificationIcon size={30} />,
    to: '/notifications/overview',
    activeOn: new RegExp(
      `^(${Routes.NOTIFICATIONS})|^(${Routes.INCIDENTS})|^(${Routes.INCIDENT_COUNTS})`,
      'g'
    ),
  },
  {
    title: 'Sites',
    icon: <SitesIcon size={30} />,
    to: `${Routes.SITES}`,
    activeOn: new RegExp(`^(${Routes.SITES})|${Routes.MAP}`, 'g'),
  },
  {
    title: 'Equipment',
    icon: <EquipmentIcon size={30} />,
    to: `${Routes.ORG_EQUIPMENT}`,
    activeOn: new RegExp(
      `^(${Routes.ORG_EQUIPMENT})|^(${Routes.HVAC_CONTROLS_OPERATIONS}?prev=equipments)`,
      'g'
    ),
    featureFlag: {
      orgBlacklist: PERFORMANCE_PAGE_TAB_BLACKLIST,
    },
  },
  {
    title: 'Reports',
    icon: <ReportCentreIcon size={30} />,
    to: `${Routes.REPORT_CENTRE}`,
    activeOn: new RegExp(`^(${Routes.REPORT_CENTRE})`, 'g'),
  },
];

const SwitchLinkWithToken = withAccessToken(({ accessToken }) => {
  const platformUrl = `${process.env.REACT_APP_PLATFORM_SWITCH_URL}`;
  return (
    <a
      href={platformUrl}
      onClick={(e) => {
        handlePlatformSwitch(e, accessToken, platformUrl);
      }}
    >
      <ApplicationMenuItem icon={<SetupIcon size={23} />} title="Setup" />
    </a>
  );
});

function Navigation({ noNavigation, routeToSavingsPage }: Props) {
  if (noNavigation) {
    return null;
  }
  /**
   * CTRL-3718 - Delete after the POC for EB Platform Performance Review Dashboard
   * Route to savings instead of dashboard
   */
  const customMenuItems =
    routeToSavingsPage === true ? menuItems(true) : menuItems(false);

  const isMobileMenuOpen = useSelector(
    ({ app }: ApplicationState) => app.isMobileMenuOpen
  );
  const passwordUpdateEnforced = useSelector<
    ApplicationState,
    boolean | undefined
  >(({ app }) => app.passwordUpdateEnforced);
  const dispatch = useDispatch();
  const toggleMobileVerMenu = () => dispatch(toggleMobileMenu());

  const userPermission = useGetUserPermission();

  //user can see button to redirect to Connect if
  //the user has global permissions or
  //has permissions for any site higher than Viewer (aka Manager/Admin)
  const shouldShowSwitchLink = determineShouldShowSwitchLink(userPermission);

  return (
    <ApplicationMenu
      logoLink={Routes.TODAY}
      isMobileMenuOpen={isMobileMenuOpen}
      onMobileMenuBackdropClick={toggleMobileVerMenu}
    >
      <div className={styles.navWrapper}>
        {passwordUpdateEnforced ? (
          <></>
        ) : (
          <div>
            {customMenuItems.map(
              ({ to, icon, title, activeOn, featureFlag }, index) => {
                const link = (
                  <Route
                    key={`${title}${index}`}
                    children={({ location }) => (
                      <Link to={to} onClick={toggleMobileVerMenu}>
                        <ApplicationMenuItem
                          isActive={
                            !!(location && location.pathname.match(activeOn))
                          }
                          icon={icon}
                          title={title || ''}
                        />
                      </Link>
                    )}
                  />
                );
                if (featureFlag === undefined) return link;
                const { orgBlacklist, orgWhitelist, applyBlacklistStrictly } =
                  featureFlag;
                return (
                  <FeatureFlag
                    key={`${title}${index}`}
                    orgBlacklist={orgBlacklist}
                    orgWhitelist={orgWhitelist}
                    applyBlacklistStrictly={applyBlacklistStrictly}
                  >
                    {link}
                  </FeatureFlag>
                );
              }
            )}
          </div>
        )}

        {shouldShowSwitchLink && (
          <div className={styles.settingsNav}>
            <SwitchLinkWithToken />
          </div>
        )}
      </div>
    </ApplicationMenu>
  );
}

export default withRouter(Navigation);
