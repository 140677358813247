import {
  DateFilter,
  GenericMultipleFilter,
  IconButton,
  Loader,
} from '@energybox/react-ui-library/dist/components';
import {
  Equipment,
  EquipmentType,
  Incident,
  IncidentPriority,
  IncidentStatus,
  SentinelType,
  SingleGenericFilter,
} from '@energybox/react-ui-library/dist/types';
import {
  capitalizeFirstLetterOnly,
  filterResolvedIncidentsOutsideOfTimeRange,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';

import React, { useEffect, useMemo, useState } from 'react';
import IncidentPriorityFilter from '../../../../components/Filters/IncidentPriorityFilter';
import IncidentStatusFilter from '../../../../components/Filters/IncidentStatusFilter';
import SentinelTypeFilter from '../../../../components/Filters/SentinelTypeFilter/SentinelTypeFilter';
import EquipmentTypeFilter from '../../../../components/Filters/EquipmentTypeFilter/EquipmentTypeFilter';
import SiteFilter from '../../../../components/Filters/SiteFilter/SiteFilter';
import IncidentsCardList from '../../../../components/IncidentsCardList';
import NotificationsTopNavigationBar from '../../../../components/TopNavigationBar/NotificationsTopNavigationBar';
import { useTimeFilter } from '../../../../hooks/useFilters';
import useSiteFilter from '../../../../hooks/useSiteFilter';
import { useSite } from '../../../../hooks/useSites';
import useAppLocale, {
  useIs12HrTimeFormat,
} from '../../../../hooks/useAppLocale';
import { getEquipment } from '../../../../actions/equipment';
import styles from './IncidentsListPage.module.css';
import { redirectToIncidentPageEntry } from '../../../../actions/incidents';
import { useDispatch, useSelector } from 'react-redux';
import SiteGroupFilter from '../../../../components/Filters/SiteGroupFilter';
import { ApplicationState } from '../../../../reducers';
import { sortSiteGroupTitles } from '../../../../utils/siteGroups/siteGroups';
import useSiteGroupsFilter from '../../../../hooks/useSiteGroupsFilter';
import useDynamicFilter from '../../../../hooks/useDynamicFilter';
import { useEquipmentTypesById } from '../../../../hooks/useEquipmentTypes';
import { useQuery } from '../../../../hooks/useQueryParam';
import { endOfDay, startOfDay } from 'date-fns';
import { useGetIncidentById } from '../../../../hooks/useIncidents';
import { Delete } from '@energybox/react-ui-library/dist/icons';
import {
  ViewportTypes,
  useViewportType,
} from '@energybox/react-ui-library/dist/hooks';
import history from '../../../../history';
import NothingToReportOverlay from '../../../../components/NothingToReportOverlay';
import { switchOrganization } from '../../../../actions/app';

type Props = {
  siteId?: string;
  getIncidents: Function;
};

export enum IncidentsListPageType {
  SINGLE_SITE = 'SINGLE_SITE',
  MULTI_SITE = 'MULTI_SITE',
}

const queryKeys = ['source', 'incidentId', 'notificationId', 'orgId'];
const pageRef = React.createRef<HTMLDivElement>();

const IncidentsListPage: React.FC<Props> = ({ siteId, getIncidents }) => {
  const { setSiteGroupsFilter, selectedSiteGroups, siteGroupWithoutSites } =
    useSiteGroupsFilter();
  const { selectedSiteFilters } = useSiteFilter();

  const pageType = siteId
    ? IncidentsListPageType.SINGLE_SITE
    : IncidentsListPageType.MULTI_SITE;

  const dispatch = useDispatch();
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const [isReviewDismiss, setIsReviewDismiss] = useState(false);
  const [isInReview, setIsInReview] = useState(false);

  //Hooks
  const queryParams = useQuery(queryKeys);
  const site = useSite(siteId);
  const locale = useAppLocale();
  const { timePeriod, setTimeFilter } = useTimeFilter();
  const { fromDate, toDate } = timePeriod;

  const {
    selectedFilters: selectedIncidentPriorityFilters,
    setFilter: setIncidentPriorityFilter,
  } = useDynamicFilter<string>('incidentPriority');

  const {
    selectedFilters: selectedIncidentStatusFilters,
    setFilter: setIncidentStatusFilter,
  } = useDynamicFilter<string>('incidentStatus');

  const {
    selectedFilters: selectedIncidentSentinelTypeFilters,
    setFilter: setIncidentSentinelTypeFilter,
  } = useDynamicFilter('sentinel');

  const {
    selectedFilters: selectedIncidentEquipmentTypeFilters,
    setFilter: setEquipmentTypeFilter,
  } = useDynamicFilter<number>('eqType', (value) => parseInt(value));

  const is12HrTimeFormat = useIs12HrTimeFormat();

  useEffect(() => {
    return () => {
      history.replace({ search: '' });
    };
  }, [history]);

  useEffect(() => {
    return () => {
      dispatch(redirectToIncidentPageEntry({}));
    };
  }, []);

  useEffect(() => {
    if (queryParams.orgId !== undefined && queryParams.orgId !== null) {
      const switchOrg = async () => {
        dispatch(switchOrganization(queryParams.orgId as unknown as number));
      };
      switchOrg();
    }
    if (queryParams.source === 'email') {
      setIsInReview(true);
      setTimeFilter({
        fromDate: startOfDay(new Date()),
        toDate: endOfDay(new Date()),
      });
    }
  }, []);

  useEffect(() => {
    if (isReviewDismiss && queryParams.incidentId !== '') {
      history.push(window.location.pathname);
    }
  }, [isReviewDismiss]);

  const {
    isLoading: isIncidentReviewLoading = false,
    data: incidentReview = undefined,
  } = useGetIncidentById(queryParams.incidentId);

  const {
    isLoading = false,
    isLastLoading = false,
    data: incidents = undefined,
  } = getIncidents({
    from: fromDate.toISOString(),
    to: toDate.toISOString(),
    siteIds:
      pageType === IncidentsListPageType.SINGLE_SITE
        ? [siteId]
        : selectedSiteFilters,
  });

  const siteGroupsById = useSelector(
    ({ siteGroups }: ApplicationState) => siteGroups.siteGroupsById ?? {}
  );
  const siteGroupsTitles = sortSiteGroupTitles(siteGroupsById);

  const equipmentTypesById = useEquipmentTypesById();

  //End Hooks

  // Get equipments from redux
  const equipments = useSelector(
    (state: ApplicationState) => state.equipment.equipment
  );

  React.useLayoutEffect(() => {
    if (pageType === IncidentsListPageType.SINGLE_SITE && siteId) {
      dispatch(
        getEquipment({
          siteIds: [parseInt(siteId)],
        })
      );
    } else {
      dispatch(getEquipment());
    }
  }, [dispatch, getEquipment, siteId, pageType]);

  const getEquipmentByIncident = (id) => {
    return equipments.find((e) => e.id === Number(id));
  };

  const filteredIncidentsData = () => {
    if (incidents) {
      return filterResolvedIncidentsOutsideOfTimeRange(
        incidents,
        fromDate,
        toDate
      );
    }
    return incidents;
  };

  //Local functions
  const filterIncidents = (): Incident[] => {
    if (!incidents) return [];
    let filteredIncidents: Incident[] = [...incidents];

    if (
      selectedIncidentStatusFilters.length === 0 ||
      selectedIncidentStatusFilters.includes(IncidentStatus.RESOLVED)
    ) {
      filteredIncidents = filterResolvedIncidentsOutsideOfTimeRange(
        filteredIncidents,
        fromDate,
        toDate
      );
    }

    if (selectedIncidentPriorityFilters?.length) {
      filteredIncidents = filteredIncidents.filter((i) => {
        return selectedIncidentPriorityFilters.includes(i.incidentPriority);
      });
    }

    if (selectedIncidentStatusFilters?.length) {
      filteredIncidents = filteredIncidents.filter((i) => {
        let incidentStatus = IncidentStatus.ACTIVE;

        if (i.resolved === true) {
          incidentStatus = IncidentStatus.RESOLVED;
        }

        return selectedIncidentStatusFilters.includes(incidentStatus);
      });
    }

    if (selectedIncidentSentinelTypeFilters?.length) {
      filteredIncidents = filteredIncidents.filter((i) => {
        return selectedIncidentSentinelTypeFilters.includes(i.sentinelType);
      });
    }

    if (selectedIncidentEquipmentTypeFilters?.length) {
      filteredIncidents = filteredIncidents.filter((i) => {
        const equipment = getEquipmentByIncident(i.equipmentId);
        return (
          i.equipmentId &&
          selectedIncidentEquipmentTypeFilters.includes(
            Number(equipment?.typeId)
          )
        );
      });
    }

    return filteredIncidents;
  };

  //End Local functions

  const incidentsToDisplay = filterIncidents();

  const availableSentinelTypes = useMemoAvailableSentinelTypes(
    filteredIncidentsData()
  );

  const availableEquipmentTypes = useMemoAvailableEquipmentTypes(
    equipments,
    filteredIncidentsData()
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      );
    }

    if (!incidents || incidents.length === 0 || siteGroupWithoutSites) {
      return <NothingToReportOverlay subtitle="" />;
    }

    return (
      <IncidentsCardList
        siteId={siteId}
        reviewSort={
          queryParams.source === 'email' && queryParams.incidentId !== ''
        }
        pageType={pageType}
        incidents={incidentsToDisplay}
        listPageRef={pageRef}
        isLoading={isLastLoading}
      />
    );
  };

  const renderSingleIncidentContent = () => {
    if (isIncidentReviewLoading) {
      return (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      );
    }

    return (
      <IncidentsCardList
        siteId={siteId}
        reviewMode={
          queryParams.source === 'email' && queryParams.incidentId !== ''
        }
        pageType={pageType}
        incidents={incidentReview || []}
        listPageRef={pageRef}
        isLoading={isIncidentReviewLoading}
      />
    );
  };

  const mobileFilters = [
    {
      title: 'Severity',
      setFilter: setIncidentPriorityFilter,
      items: Object.values(IncidentPriority).filter(
        //TODO: remove this once Mike O cleans up
        //functionally duplicate priorities in HIGHEST and CRITICAL
        (p) => p !== IncidentPriority.HIGHEST && p !== IncidentPriority.NORMAL
      ),
      selectedItems: selectedIncidentPriorityFilters,
      transformItemName: capitalizeFirstLetterOnly,
    },
    {
      title: 'Sentinel Type',
      setFilter: setIncidentSentinelTypeFilter,
      items: availableSentinelTypes,
      selectedItems: selectedIncidentSentinelTypeFilters,
      transformItemName: (sentinel: SentinelType) =>
        sentinel.replace(/([A-Z])/g, ' $1').trim(),
    },
    {
      title: 'Status',
      setFilter: setIncidentStatusFilter,
      items: Object.values(IncidentStatus),
      selectedItems: selectedIncidentStatusFilters,
      transformItemName: capitalizeFirstLetterOnly,
    },
    {
      title: 'Equipment Type',
      setFilter: setEquipmentTypeFilter,
      items: availableEquipmentTypes.map(({ id }) => id || NaN),
      selectedItems: selectedIncidentEquipmentTypeFilters,
      transformItemName: (equipmentTypeId) => {
        const type = equipmentTypesById[equipmentTypeId];
        return isDefined(type) ? type.title : '';
      },
    },
    pageType === IncidentsListPageType.MULTI_SITE && {
      title: 'Site',
      customFilter: () => <SiteFilter isInDropdown={true} />,
      selectedItems: selectedSiteFilters,
    },
    pageType === IncidentsListPageType.MULTI_SITE && {
      title: 'Site Groups',
      setFilter: setSiteGroupsFilter,
      items: siteGroupsTitles,
      selectedItems: selectedSiteGroups,
    },
  ].filter((item) => item) as SingleGenericFilter[];

  return (
    <>
      {pageType === IncidentsListPageType.MULTI_SITE && (
        <NotificationsTopNavigationBar path="/incidents" />
      )}

      <div
        className={`${
          pageType === IncidentsListPageType.SINGLE_SITE
            ? styles.singleSiteRoot
            : styles.multiSiteRoot
        }`}
        ref={pageRef}
      >
        {isInReview && !isReviewDismiss && incidentReview !== undefined ? (
          <>
            <div className={styles.title}>
              {'Review'}
              <span style={{ float: 'right' }}>
                <IconButton
                  onClick={() => setIsReviewDismiss(true)}
                  aria-label="cancel"
                  size="small"
                  className={styles.icon}
                >
                  <Delete size={20} color={'var(--accent-baseMinus10)'} />
                </IconButton>
              </span>
            </div>
            <div className={styles.review}>{renderSingleIncidentContent()}</div>
            {!isMobile && <div className={styles.title}>{'All Incidents'}</div>}
          </>
        ) : null}
        <div className={styles.header}>
          <div className={styles.filterContainer}>
            <div className={styles.priorityAndStatusFilters}>
              <IncidentPriorityFilter />
              <SentinelTypeFilter sentinels={availableSentinelTypes} />
              <IncidentStatusFilter />
              <EquipmentTypeFilter equipmentTypes={availableEquipmentTypes} />
            </div>
            <GenericMultipleFilter
              className={styles.mobilePriorityAndStatusFilter}
              dropdownClassName={styles.mobileFilterDropdown}
              title="Filters"
              filters={mobileFilters}
              alignItemsRight
            />

            {pageType === IncidentsListPageType.MULTI_SITE && (
              <>
                <SiteFilter className={styles.siteFilter} />
                <SiteGroupFilter
                  dropdownClassName={styles.siteGroupFilterDropdown}
                  className={styles.siteGroupFilterContainer}
                />
              </>
            )}
          </div>
          <div>
            <DateFilter
              ianaTimeZoneCode={site?.timeZone}
              useCurrentTime
              value={timePeriod}
              setFilter={setTimeFilter}
              customPickerVariant="date"
              locale={locale}
              alignItemsRight={true}
              is12HrTimeFormat={is12HrTimeFormat}
            />
          </div>
        </div>
        {isMobile && <div className={styles.title}>{'All Incidents'}</div>}
        {renderContent()}
      </div>
    </>
  );
};

const useMemoAvailableSentinelTypes = (incidents: Incident[]) => {
  return useMemo(() => {
    if (!incidents) {
      return [];
    }
    const availableSentinelTypes = incidents.reduce<SentinelType[]>(
      (acc, incident) => {
        if (!acc.includes(incident.sentinelType)) {
          acc.push(incident.sentinelType);
        }
        return acc;
      },
      []
    );
    return availableSentinelTypes;
  }, [incidents]);
};

const useMemoAvailableEquipmentTypes = (
  equipments: Equipment[],
  incidents: Incident[]
) => {
  return useMemo(() => {
    if (!equipments || !incidents) {
      return [];
    }
    const accIds: number[] = [];
    const availableEquipmentTypes = incidents.reduce<EquipmentType[]>(
      (acc, incident) => {
        if (incident.equipmentId) {
          const equipment = equipments.find(
            (e) => e.id === Number(incident.equipmentId)
          );
          if (equipment && equipment.type) {
            if (!accIds.includes(equipment.typeId)) {
              accIds.push(equipment.typeId);
              acc.push(equipment.type);
            }
            incident.equipmentTypeId = String(equipment.typeId);
          }
        }
        return acc;
      },
      []
    );
    return availableEquipmentTypes;
  }, [equipments, incidents]);
};

export default IncidentsListPage;
